<template>
  <b-card>
    <b-card-header>
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <span class="font-weight-bold">{{ $t(resourcesElementName + '.name') }}</span>
      </b-col>
    </b-card-header>
    <b-card-body>

      <validation-observer
        ref="elementForm"
        #default="{ invalid }"
      >
        <b-form
          @submit.prevent="save"
        >
          <b-row>
            <b-col>
              <Calendar
                :data-source="dataSource"
                :year="currentYear"
                :min-date="minDate"
                :language="language"
                :render-style="style"
                :custom-data-source-renderer="dayRender"
                :allow-overlap="allowOverlap"
                :enable-range-selection="enableRangeSelection"
                :display-week-number="displayWeekNumber"
                :round-range-limits="roundRangeLimits"
                :always-half-day="alwaysHalfDay"
                @select-range="selectRange"
              />
            </b-col>
          </b-row>
          <b-row class="d-flex justify-content-end">
            <b-button
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              @click="router().go(-1)"
            >
              {{ $t('actions.back') }}
            </b-button>
            <b-button
              variant="primary"
              type="submit"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :disabled="invalid"
            >
              {{ $t('actions.save') }}
            </b-button>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-body>
    <div>
      <b-modal
        id="modal-1"
        hide-footer
        :title="$t(resourcesElementName + '.object.edit_title')"
      >
        <template>
          <dates-edit-tab
            v-if="elementData"
            :type-list="typeList"
            :element-data="currentDate"
            class="mt-2 pt-75"
          />
        </template>
      </b-modal>
    </div>
  </b-card>

</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BButton, BAvatar, BRow, BCol, BBadge, BFormInput, BFormGroup, BForm, BModal,
} from 'bootstrap-vue'
import Calendar from 'v-year-calendar'
import 'v-year-calendar/locales/v-year-calendar.es'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import router from '@/router'
import DatesEditTab from './EditDates.vue'

export default {
  components: {
    DatesEditTab, BModal, BCard, BButton, BRow, BCol, BAvatar, BBadge, BFormInput, BCardHeader, BCardBody, BFormGroup, BForm, ValidationProvider, ValidationObserver, Calendar,
  },
  props: {
    elementData: {
      type: Array,
      required: true,
    },
    typeList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      resourcesElementName: 'availabilities',
      required,

      minDate: undefined,
      currentYear: new Date().getFullYear(),
      language: 'es',
      style: 'custom',
      allowOverlap: true,
      enableRangeSelection: true,
      displayWeekNumber: false,
      roundRangeLimits: false,
      alwaysHalfDay: false,
      dataSource: [],
      currentDate: {},
    }
  },
  watch: {
    elementData(newValue, oldValue) {
      if (this.updateDates) {
        this.updateDates(newValue)
      }
    },
  },
  beforeDestroy() {
    this.$root.$off('cancel-dates')
    this.$root.$off('save-dates')
    this.$root.$off('remove-dates')
  },
  mounted() {
    this.$root.$on('cancel-dates', () => {
      this.updateDates(this.elementData)
      this.$root.$emit('bv::hide::modal', 'modal-1')
    })

    this.$root.$on('save-dates', contactData => {
      this.dataSource.pop()
      console.log('saving', contactData, this.elementData)
      if (!this.currentDate || !this.currentDate.startDate) {
        return
      }
      this.currentDate.color = 'red'
      const d = new Date(this.currentDate.startDate.getTime())
      for (d; d <= this.currentDate.endDate; d.setDate(d.getDate() + 1)) {
        let found = false
        for (const ava of this.elementData) {
          const date = new Date(ava.date)
          if (date.getFullYear() === d.getFullYear()
            && date.getMonth() === d.getMonth()
            && date.getDate() === d.getDate()
          ) {
            ava.date = this.currentDate.date
            ava.type = this.currentDate.type
            ava.reason = this.currentDate.reason
            ava.type_id = this.currentDate.type_id
            found = true
            break
          }
        }
        if (!found) {
          const da = {
            date: new Date(d.getTime()),
            type: this.currentDate.type,
            reason: this.currentDate.reason,
            type_id: this.currentDate.type_id,
          }
          this.elementData.push(da)
        }
      }

      this.dataSource.push(this.currentDate)
      this.updateDates(this.elementData)
    })

    this.$root.$on('remove-dates', contactData => {
      this.dataSource.pop()
      if (!this.currentDate || !this.currentDate.startDate || !this.currentDate.endDate) {
        return
      }
      this.currentDate.color = 'red'
      const d = new Date(this.currentDate.startDate.getTime())
      for (d; d <= this.currentDate.endDate; d.setDate(d.getDate() + 1)) {
        for (const ava of this.elementData) {
          const date = new Date(ava.date)
          if (date.getFullYear() === d.getFullYear()
            && date.getMonth() === d.getMonth()
            && date.getDate() === d.getDate()
          ) {
            console.log('found')
            this.elementData.splice(this.elementData.indexOf(ava), 1)
            break
          }
        }
      }
      const idx = this.dataSource.indexOf(this.currentDate)
      if (idx > -1) {
        this.dataSource.splice(idx, 1)
      }
      this.updateDates(this.elementData)
    })
  },
  methods: {
    router() {
      return router
    },
    dayRender(e) {
      e.classList.add('dayRender')
    },
    updateDates(newValue) {
      this.dataSource = []
      console.log('updating', newValue)
      if (newValue) {
        for (const ava of newValue) {
          this.dataSource.push({
            startDate: new Date(ava.date),
            endDate: new Date(ava.date),
            color: 'red',
          })
        }
      }
    },
    save() {
      const _self = this
      this.$refs.elementForm.validate().then(success => {
        if (success) {
          _self.$parent.save(_self.elementData)
        }
      })
    },
    selectRange(e) {
      let state = 'new'
      this.currentDate = {
        startDate: e.startDate,
        endDate: e.endDate,
        date: e.date,
        type_id: e.type_id,
        type: e.type,
        color: '#999999',
        reason: undefined,
      }

      const d = new Date(e.startDate.getTime())
      for (d; d <= e.endDate; d.setDate(d.getDate() + 1)) {
        let found = false
        for (const ava of this.elementData) {
          const date = new Date(ava.date)
          if (date.getFullYear() === d.getFullYear()
            && date.getMonth() === d.getMonth()
            && date.getDate() === d.getDate()) {
            if (this.currentDate.date === undefined) {
              this.currentDate.date = ava.date
              this.currentDate.type_id = ava.type_id
              this.currentDate.type = ava.type
              this.currentDate.reason = ava.reason
              state = 'single'
              found = true
            } else if (state !== 'many'
              && (this.currentDate.date !== ava.date)
            ) {
              state = 'many'
              found = true
              this.currentDate.type_id = undefined
              this.currentDate.reason = ''
            }
          }
        }
        if (!found) {
          if (state !== 'single' && state != 'many') {
            state = 'new'
          } else {
            state != 'many'
          }
          this.currentDate.type_id = undefined
          this.currentDate.reason = ''
        }
      }

      this.currentDate.state = state

      this.dataSource.push(this.currentDate)
      this.$root.$emit('bv::show::modal', 'modal-1')
    },
  },
}
</script>

<style>
 .calendar {
    overflow-x: visible !important;
 }
 .dayRender {
    color: red !important;
  }
</style>
