<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="holidays === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
    </b-alert>

    <div class="text-primary h2">
      <feather-icon
        icon="UserIcon"
        size="25"
        class="mr-0 mr-sm-50"
        variant="primary"
      />
      <span class="d-none d-sm-inline">Editar Festivos</span>
    </div>
    <edit-card
      v-if="holidays"
      :element-data="holidays"
      :type-list="typeList"
      class="mt-2 pt-75"
    />
  </div>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import router from '@/router'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ElementStoreModule from '../storeModule'
import EditCard from './EditCard.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    EditCard,
  },
  data() {
    return {
      typeList: [],
      holidays: [],
      ELEMENT_APP_STORE_MODULE_NAME: 'app-holidays',
    }
  },
  async mounted() {
    // Register module
    if (!store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME)) store.registerModule(this.ELEMENT_APP_STORE_MODULE_NAME, ElementStoreModule)

    await this.fetchElements()
    this.typeList = await this.fetch_key_value('holiday_type')

  },
  unmounted() {
    // UnRegister on leave
    if (store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME)) store.unregisterModule(this.ELEMENT_APP_STORE_MODULE_NAME)
  },
  methods: {
    async fetch_key_value(type) {
      return store.dispatch(this.ELEMENT_APP_STORE_MODULE_NAME + '/fetch_key_value', { type: type }).then(response => {
        var list = response.data.items
        list.unshift({ id: null, value: this.$t('list.defaultOption') })
        return list
      }).catch(error => {
        if (error.response.status === 404) {
          return undefined
        }
      })
    },
    fetchElements() {
      var _self = this
      store.dispatch(this.ELEMENT_APP_STORE_MODULE_NAME + '/fetchElements').then(response => { 
        _self.holidays = response.data 
      }).catch(error => {
        if (error.response.status === 404) {
          console.info('404', _self.holidays)
          _self.holidays = undefined
        }
      })
    },
    save(holidays) {
      console.info('holidays:', holidays)

      this.holidays = holidays

      store.dispatch(this.ELEMENT_APP_STORE_MODULE_NAME + '/saveElements', { elementData: this.holidays } )
      .then(response => { 
        this.fetchElements()
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Festivos actualizados',
            icon: 'SaveIcon',
            variant: 'success',
            text: `Los festivos de la compañía han sido actualizados correctamente`,
          },
        })
      }).catch(error => {
        if (error.response.status === 404) {
          this.holidays = undefined
        }
      })
    }
  }
}
</script>

<style>

</style>
