import axios from '@axios'
import useJwt from '@core/auth/jwt/useJwt'

const { jwt } = useJwt(axios, {})
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetch_key_value(ctx, { type }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/core/type_value/${type}`, {
            params: { deep: 1 },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchElements(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/core/avenir_holiday', { })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    saveElements(ctx, elementData) {
      const data = elementData.elementData
      return new Promise((resolve, reject) => {
        axios
          .post('/api/v1/core/avenir_holiday', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
